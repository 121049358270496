<template>
    <div id="newAssetCategoriesList" class="baseTemplate">

        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">

            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">

                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="newAssetCategory"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">

                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="asset_createNewCategorySubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <!--begin::Portlet -->
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon kt-hidden">
                                                    <i class="la la-gear"></i>
                                                </span>
                                                <h3 class="kt-portlet__head-title">{{ $t("asset_createNewCategory") }}</h3>
                                            </div>
                                        </div>
                                        <!--begin::Form-->
                                        <form class="kt-form">
                                            <div class="kt-portlet__body">
                                                <div class="kt-section kt-section--last">

                                                    <h3 class="kt-section__title">1. {{ $t("asset_editCategoryInfo") }}</h3>

                                                    <div class="kt-section__body">

                                                        <div class="form-group validated">
                                                            <label for="newCategoryForm_nameInput">{{ $t("asset_categoryName") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                </div>
                                                                <input v-model="vName" maxlength="50" type="text" class="form-control" id="newCategoryForm_nameInput" :placeholder="$t('common_enterName')" />
                                                                <div v-if="!$v.vName.required" class="invalid-feedback">{{ $t("error_fieldIsRequired") }}</div>
                                                                <div v-else-if="!$v.vName.minLen" class="invalid-feedback">{{ $t("error_minLengthMsg", [$v.vName.$params.minLen.min]) }}</div>
                                                            </div>
                                                            <span class="form-text text-muted"> {{ $t("asset_categoryNameExample") }}</span>
                                                        </div>

                                                        <div class="row form-group">
                                                            <div class="col-md-6">
                                                                <div>
                                                                    <div class="row" style="margin-left: 0px;">
                                                                        <label class="col-form-label mr-3" id="peopleCategoryStatusLabel">{{ $t("asset_categoryIsItPeople") }}</label>
                                                                        <div>
                                                                            <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--primary">
                                                                                <label>
                                                                                    <input type="checkbox" checked="checked" id="isPeopleCategoryCheckbox" v-model="isPeopleCategory" @change="onChangeIsPeopleCategory">
                                                                                    <span></span>
                                                                                </label>
                                                                            </span>
                                                                        </div>
                                                                        <label class="col-form-label ml-1" id="peopleCategoryStatusMessage">{{ peopleCategoryStatusMessage }}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="form-group row validated">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("asset_categoryFileBrowser") }} *</label>
                                                                <div class="custom-file">
                                                                    <input type="file" @change="handleFileSelected" ref="imageAssetFile" :lang="this.$i18n.locale" class="custom-file-input cursorPointer" accept="image/gif, image/jpeg, image/png" id="selectAssetImageInputFile" />
                                                                    <label class="custom-file-label textLeft" id="selectAssetImageInputLabel" for="customFile">{{ $t("common_chooseFile") }}</label>
                                                                    <div v-if="!$v.imageName.required" class="invalid-feedback">{{ $t("error_fieldIsRequired") }}</div>
                                                                    <span class="form-text text-muted">{{ $t("site_selectImageHelpText") }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-1" />
                                                            <div v-show="isImageLoaded" class="col-lg-5">
                                                                <div id="newCategoryForm_imagePreview" class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + this.image + ')' }"></div>
                                                                <div class="kt-margin-b-10 kt-margin-t-10">{{ imageName }}</div>
                                                                <div>
                                                                    <button id="newAssetRemoveImageButton" type="button" @click="onRemoveImage" class="btn btn-outline-brand">{{ $t("common_remove") }}</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="form-group row validated" v-show="isImageLoaded">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("asset_categoryIconSizeMap") }} *</label>
                                                                <select id="newCategoryForm_iconSizeRadio" class="form-control kt-select2">
                                                                    <!-- To display placeholder -->
                                                                    <option></option>
                                                                </select>
                                                                <div v-if="!$v.vIconSize.required" class="invalid-feedback">{{ $t("error_fieldIsRequired") }}</div>
                                                                <span class="form-text text-muted">{{ $t("asset_categoryIconSizedetailInfo") }}</span>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                    <h3 class="kt-section__title">2. {{ $t("asset_editSubcategoriesInfo") }}</h3>

                                                    <div class="kt-section__body">
                                                        <div role="alert" class="alert alert-secondary">
                                                            <div class="alert-icon">
                                                                <i class="flaticon-questions-circular-button kt-font-brand"></i>
                                                            </div>
                                                            <div class="alert-text">
                                                                <span>{{ $t("asset_subcategoriesHelpL1") }}</span>
                                                                <ul>
                                                                    <li>{{ $t("asset_subcategoriesHelpL2") }}</li>
                                                                    <li>{{ $t("asset_subcategoriesHelpL3") }}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="kt-form__section kt-form__section--first">
                                                                <div id="kt_repeater_subcategory">
                                                                    <div class="form-group row">
                                                                        <div data-repeater-list="kt_repeater_subcategory-list" class="col-lg-6">
                                                                            <div data-repeater-item class="kt-margin-b-10">
                                                                                <div class="input-group">
                                                                                    <input type="text" name="text-name" class="form-control" :placeholder="$t('common_enterName')" />
                                                                                    <div class="input-group-append">
                                                                                        <a href="javascript:;" data-repeater-delete class="btn btn-danger btn-icon">
                                                                                            <i class="la la-close"></i>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col">
                                                                            <div id="newAssetCategoryCreateSubCategoryButton" data-repeater-create="" class="btn btn btn-brand marginTM30">
                                                                                <span>
                                                                                    <i class="la la-plus"></i>
                                                                                    <span>{{ $t("asset_addSubcategory") }}</span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                    <h3 class="kt-section__title">3. {{ $t("asset_editCustomFieldsInfo") }}</h3>

                                                    <div class="kt-section__body">
                                                        <div role="alert" class="alert alert-secondary">
                                                            <div class="alert-icon">
                                                                <i class="flaticon-questions-circular-button kt-font-brand"></i>
                                                            </div>
                                                            <div class="alert-text">
                                                                <span>{{ $t("asset_customfieldsHelpL1") }}</span>
                                                                <ul>
                                                                    <li>{{ $t("asset_customfieldsHelpL2") }}</li>
                                                                    <li>{{ $t("asset_customfieldsHelpL3") }}</li>
                                                                    <li>{{ $t("asset_customfieldsHelpL4") }}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="kt-form__section kt-form__section--first">
                                                                <div id="kt_repeater_customfield">
                                                                    <div class="form-group row validated">
                                                                        <label class="col-lg-4 col-form-label">{{ $t("common_name") }}</label>
                                                                        <label class="col-lg-3 col-form-label">{{ $t("common_type") }}</label>
                                                                        <label class="col-lg-5 col-form-label">{{ $t("common_enumeration") }}</label>
                                                                        <div data-repeater-list="kt_repeater_customfield-list" class="col-lg-12">
                                                                            <div v-for="(item, index) in customFields" :key="index" class="form-group row kt-margin-b-10">
                                                                                <div class="col-lg-4">
                                                                                    <div class="input-group">
                                                                                        <input type="text" v-model="item.name" name="text-name" class="form-control" :placeholder="$t('common_enterName')" />
                                                                                        <div class="input-group-append">
                                                                                            <a href="javascript:;" @click="removeCustomFieldItem(index)" class="btn btn-danger btn-icon">
                                                                                                <i class="la la-close"></i>
                                                                                            </a>
                                                                                        </div>
                                                                                        <div v-if="!$v.customFields.$each[index].name.required" class="invalid-feedback">{{ $t("error_fieldIsRequired") }}</div>
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <div class="input-group">
                                                                                        <select name="select-type" :id="'select_type_select_'+ index" class="form-control kt-select2">
                                                                                            <option></option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-5">
                                                                                    <div class="input-group" style="display:none;">
                                                                                        <select name="select-enums" :data-enum="index" multiple="multiple" class="form-control kt-select2">
                                                                                            <option></option>
                                                                                        </select>
                                                                                    </div>
                                                                                    <div class="input-group validated" style="display:none;">
                                                                                        <input type="text" v-model="item.url" name="text-URL" :data-url="index" class="form-control" :placeholder="$t('common_enterURL')" />
                                                                                    </div>
                                                                                    <div v-if="!$v.customFields.$each[index].url.required" class="invalid-feedback">{{ $t("error_fieldIsRequired") }}</div>
                                                                                    <div v-if="isValidURLs[index] === false" class="invalid-feedback">{{ $t("error_fieldURLInvalid") }}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col">
                                                                            <div id="newAssetCategoryCreateCustomFieldButton" @click="addCustomFieldItem()" class="btn btn btn-brand marginTM30 cursorPointer">
                                                                                <span>
                                                                                    <i class="la la-plus"></i>
                                                                                    <span>{{ $t("asset_addCustomfield") }}</span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <label class="kt-checkbox kt-margin-r-20">
                                                        <input v-model="createAnother" type="checkbox" /> {{ $t("common_createAnother") }}
                                                        <span></span>
                                                    </label>
                                                    <button v-if="$v.$invalid || isInvalidateURLs" id="newAssetCategoryUpdateButton" type="button" :disabled="$v.$invalid || isInvalidateURLs" class="btn btn-brand kt-margin-r-5">
                                                        <i class="la la-save"></i>
                                                        {{ $t("common_add") }}
                                                    </button>
                                                    <button v-else id="newAssetCategoryUpdateButton" @click="onCreateButton" type="button" :disabled="$v.$invalid || isInvalidateURLs" class="btn btn-brand kt-margin-r-5">
                                                        <i class="la la-save"></i>
                                                        {{ $t("common_add") }}
                                                    </button>
                                                    <button id="newAssetCategoryCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                    <!--end::Portlet Category -->
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength, requiredIf } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "../site/widgets/sitetitle.vue";
import i18n from "../../i18n";
import commonVueHelper from "../../helpers/commonVueHelper";

const _iconSizes = require("../../constants/iconSizes");

export default {
    data() {
        console.log("Component(NewCategory)::data() - called");
        return {
            siteId: this.$route.params.siteId,
            iconSizeInPixel: {},
            customFieldTypesData: [],
            vName: "",
            vIconSize: "",
            image: "",
            imageName: "",
            isImageLoaded: false,
            createAnother: false,
            isPeopleCategory: false,
            peopleCategoryStatusMessage: i18n.t("common_no"),
            customFields: [
                {
                    name: "",
                    isDefault: false,
                    typec: "",
                    enumc: [],
                    url: ""
                }
            ],
            isValidURLs: [],
            isInvalidateURLs: false
        };
    },
    created: function() {
        console.log("Component(NewCategory)::created() - called");
        this.createAnother = this.createAnotherAssetCategory;
        for (let i = 0; i < _iconSizes.list.length; i++) {
            let pixelSize = "16px";
            switch (_iconSizes.list[i].toLowerCase()) {
                case "small":
                    pixelSize = "24px";
                    break;
                case "medium":
                    pixelSize = "48px";
                    break;
                case "large":
                    pixelSize = "96px";
                    break;
                default:
            }
            this.iconSizeInPixel[_iconSizes.list[i]] = pixelSize;
            this.customFieldTypesData = commonVueHelper.getCustomFieldTypesData();
        }
    },
    mounted: function() {
        console.log("Component(NewCategory)::mounted() - Init metronic layout");
        KTLayout.init();

        this.initIconSizeSelect2();
        this.initSubcategories();
        this.initCustomFields();
    },
    destroyed: function() {
        console.log("Component(NewCategory)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        vName: {
            required,
            minLen: minLength(2)
        },
        vIconSize: {
            required
        },
        imageName: {
            required
        },
        customFields: {
            required,
            $each: {
                name: {
                    required: requiredIf(function(customFiled) {
                        return customFiled.typec === "URL";
                    })
                },
                url: {
                    required: requiredIf(function(customFiled) {
                        return customFiled.typec === "URL";
                    })
                }
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["createAnotherAssetCategory", "user"])
    },
    watch: {
        // Watch the changed of user language
        user: function(user) {
            console.log("Component(NewCategory)::watch(user) - called with ", user);
            if (user) {
                if (this.isPeopleCategory) {
                    this.peopleCategoryStatusMessage = i18n.t("common_yes");
                } else {
                    this.peopleCategoryStatusMessage = i18n.t("common_no");
                }
                // Translate icon file browser
                commonVueHelper.resetImageInputLabel(this.isImageLoaded);
                // Translate image size select2
                this.updateIconSizeSelect2();
                // Translate all subcategories input name placeholder
                this.translateRepeaterInputTextNamePlaceholder("kt_repeater_subcategory", "text-name");
                // Translate all custom field input name placeholder
                this.translateRepeaterInputTextNamePlaceholder("kt_repeater_customfield", "text-name");
                // Translate all custom field input url placeholder
                this.translateRepeaterInputTextURLPlaceholder("kt_repeater_customfield", "text-URL");
                // Translate all custom field select multi values placeholder
                this.translateRepeaterSelectValuesPlaceholder();
                // Translate all custom field type select2
                this.translateRepeaterSelectType();

                for (let i = 0; i < this.customFields.length; i ++) {
                    const selectTypeSelector = $("#select_type_select_" + i);
                    this.initTypeSelect2(selectTypeSelector, this.customFields[i]["typec"], i);
                }
            }
        },

        customFields: {
            handler(customFields) {
                for(let i = 0; i < customFields.length; i++) {
                    let res;
                    if (customFields[i]["typec"] === "URL" && customFields[i]["url"]) {
                        res = customFields[i]["url"].match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                    }
                    if (res === null && customFields[i]["typec"] === "URL" && customFields[i]["url"] !== "") {
                        this.isValidURLs[i] = false;
                    } else {
                        this.isValidURLs[i] = true;
                    }
                }
                this.isInvalidateURLs = this.isValidURLs.find(item => item === false) !== undefined ? true : false;
            },
            deep: true,
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["createAssetCategory", "setCreateAnotherAssetCategory", "sendNotification"]),

        addCustomFieldItem() {

            // add the new condition rule in the conditions
            if (!this.customFields) {
                this.customFields = [];
            }
            this.customFields.push({
                name: "",
                isDefault: false,
                typec: "",
                enumc: [],
                url: ""
            });
            const index = this.customFields.length - 1;
            setTimeout(() => {
                const selectTypeSelector = $("#select_type_select_" + index);
                this.initTypeSelect2(selectTypeSelector, null, index);
            }, 50);
        },

        removeCustomFieldItem(index) {
            if (confirm(i18n.t("common_deleteEltConfirmText"))) {
                this.customFields.splice(index, 1);
            }
        },

        createAssociatedListCustomField(customFields) {
            const data = [];
            for (let i = 0; i < customFields.length; i++) {
                let item = {};
                let name = customFields[i]["name"];

                if (name) {
                    item.name = name;
                    item.isDefault = false;
                    let type = customFields[i]["typec"];
                    item.typec = type;

                    if (type === "Enum") {
                        item.enumc = customFields[i]["enumc"];

                        if (!item.enumc || item.enumc.length === 0) {
                            this.sendNotification({
                                text: i18n.t("asset_emptyCustomFieldValuesList", { name: name }),
                                type: "error"
                            });
                            return null;
                        }
                    } else if (type === "URL") {
                        item.staticValue = customFields[i]["url"];
                        if (!item.staticValue) {
                            this.sendNotification({
                                text: i18n.t("asset_emptyCustomFieldValuesList", { name: name }),
                                type: "error"
                            });
                            return null;
                        }
                    }
                    data.push(item);
                }
            }
            return data;
        },

        // Translate input name placeholder
        translateRepeaterInputTextNamePlaceholder(repeaterId) {
            const inputTextNameSelectors = $("#" + repeaterId).find("input[name$='[text-name]']");
            for (let inputTextNameSelector of inputTextNameSelectors) {
                if (inputTextNameSelector.placeholder) {
                    inputTextNameSelector.placeholder = i18n.t("common_enterName");
                }
            }
        },

        // Translate input URL placeholder
        translateRepeaterInputTextURLPlaceholder(repeaterId) {
            const inputTextURLSelectors = $("#" + repeaterId).find("input[name$='[text-URL]']");
            for (let inputTextURLSelector of inputTextURLSelectors) {
                if (inputTextURLSelector.placeholder) {
                    inputTextURLSelector.placeholder = i18n.t("common_enterURL");
                }
            }
        },

        // Translate select2 of custom fields type
        translateRepeaterSelectType() {
            const previousTypesData = [...this.customFieldTypesData];
            this.customFieldTypesData = commonVueHelper.getCustomFieldTypesData();
            const inputTypeSelectors = $("#kt_repeater_customfield").find("input[name$='[text-type]']");
            const selectTypeSelectors = $("#kt_repeater_customfield").find("select[name*='[select-type]']");
            for (let i=0; i<selectTypeSelectors.length; i++) {
                if ($(selectTypeSelectors[i]).css('display') !== 'none') {
                    // Translate the select2 type
                    this.updateTypeSelect2($(selectTypeSelectors[i]));
                } else {
                    // Type cannot be changed => Translate the disabled input type
                    const oldTypeData = previousTypesData.find(item => item.text ===  $(inputTypeSelectors[i]).val());
                    if (oldTypeData && oldTypeData.id) {
                        const newTypeData = this.customFieldTypesData.find(item => item.id ===  oldTypeData.id);
                        if (newTypeData && newTypeData.text) {
                            $(inputTypeSelectors[i]).val(newTypeData.text);
                        }
                    }
                }
            }
        },

        // Translate all custom field select multi values placeholder
        translateRepeaterSelectValuesPlaceholder() {
            let selectSelectors = $("#kt_repeater_customfield input.select2-search__field");
            for (let selectSelector of selectSelectors) {
                if (selectSelector.placeholder) {
                    selectSelector.placeholder = i18n.t("common_enterValues");
                }
            }
        },

        // Function called when user change the is people category switcher
        onChangeIsPeopleCategory() {
            if (this.isPeopleCategory) {
                this.peopleCategoryStatusMessage = i18n.t("common_yes");
            } else {
                this.peopleCategoryStatusMessage = i18n.t("common_no");
            }
        },

        // Function called to init icon size select2
        initIconSizeSelect2() {
            let self = this;
            // Init unit Select2
            commonVueHelper.destroySelect2($("#newCategoryForm_iconSizeRadio"));
            $("#newCategoryForm_iconSizeRadio")
                .select2({
                    placeholder: i18n.t("asset_selectIconSize"),
                    width: "100%",
                    data: commonVueHelper.getIconSizesData(),
                    minimumResultsForSearch: -1
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.vIconSize = $(this).val();
                });
        },

        updateIconSizeSelect2() {
            let self = this;
            $("#newCategoryForm_iconSizeRadio")
                .empty()
                .select2({
                    placeholder: i18n.t("asset_selectIconSize"),
                    data: commonVueHelper.getIconSizesData()
                })
                .val(self.vIconSize)
                .trigger("change")
        },

        // Function called when user select an image
        handleFileSelected() {
            console.log("Component(NewAsset)::handleFileSelected() - called");
            if (this.$refs.imageAssetFile.files.length > 0) {
                if (this.$refs.imageAssetFile.files[0].size > 3145728) {
                    // 3 MB max
                    alert(i18n.t("common_fileTooBig", { fileLimit: "3" }));
                    this.image = "";
                    this.imageName = "";
                    this.isImageLoaded = false;
                } else {
                    let imageFile = this.$refs.imageAssetFile.files[0];
                    this.imageName = imageFile.name;
                    let reader = new FileReader();
                    reader.readAsDataURL(imageFile);
                    reader.onload = () => {
                        this.image = reader.result;
                        this.isImageLoaded = true;
                        // Set image to its initial size
                        let i = new Image();
                        i.onload = () => {
                            // Change icon size according to the icon size (Samll, Medium or Large)
                            this.onChangeIconSize();
                        };
                        i.src = this.image;
                    };
                }
            }
        },

        // Function called when user click on the "Remove" button to remove image
        onRemoveImage() {
            this.image = "";
            this.imageName = "";
            this.isImageLoaded = false;
            // Force clear of input file to be able to select the same image again
            $("#selectAssetImageInputFile").val(null);
            // Replace removed file path label by common_chooseFile text
            commonVueHelper.resetImageInputLabel();
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(NewCategory)::onCancelButton() - called");
            this.$router.push({ name: "assetCategories", params: { fromAction: "cancelButton" } });
        },

        onCreateButton() {
            console.log("Component(NewCategory)::onCreateButton() - called");
            let data = {
                siteId: this.$route.params.siteId,
                isDefault: false,
                name: this.vName,
                iconSize: this.vIconSize,
                isPeopleCategory: this.isPeopleCategory,
                createAnother: this.createAnother
            };
            if (this.imageName) {
                data.iconName = this.imageName;
            }
            if (this.image) {
                data.iconImg = this.image;
            }
            data.subcategories = this.createAssociatedListWithRepeater("kt_repeater_subcategory", "text-name");
            data.customFields = this.createAssociatedListCustomField(this.customFields);
            if (data.subcategories && data.customFields) {
                this.createAssetCategory(data);
            }
        },

        onChangeIconSize() {
            // Change icon size according to the icon size (Samll, Medium or Large)
            $("#newCategoryForm_iconPreview").css("max-width", this.iconSizeInPixel[this.vIconSize]);
            $("#newCategoryForm_iconPreview").css("max-height", this.iconSizeInPixel[this.vIconSize]);
            $("#newCategoryForm_iconPreview").css("width", "100%");
            $("#newCategoryForm_iconPreview").css("height", "100%");
        },

        initSubcategories() {
            console.log("Component(NewCategory)::watch initSubcategories - called");
            // Setup the repeater for subcategories
            let subcategoriesRepeater = this.initRepeater("kt_repeater_subcategory", "text-name");
            //Set the values of the inputs as a formatted object
            subcategoriesRepeater.setList({ "text-name": null });
        },

        initCustomFields() {
            console.log("Component(NewCategory)::watch initCustomFields - called");
            // Setup the repeater for custom fields
            // let customFieldsRepeater = this.initRepeater("kt_repeater_customfield", "text-name", "select-type", "select-enums");
            // Add an empty field to display
            // customFieldsRepeater.setList({ "text-name": null });
            const selectTypeSelector = $("#select_type_select_0");
            this.initTypeSelect2(selectTypeSelector, null, 0);
        },

        // Initialize a repeater (use to add and remove a repeatable group of input elements)
        initRepeater(repeaterId, repeaterTextNameKey, repeaterSelectTypeKey, repeaterSelectEnumsKey) {
            let mySelf = this; // WARNING !!! Do not use "self" name, because it's overwritten by repeater callback

            let myRepeater = $("#" + repeaterId).repeater({
                // start with an empty list of repeaters. Set your first (and only)
                // "data-repeater-item" with style="display:none;" and pass the
                // following configuration flag
                initEmpty: false,

                // "show" is called just after an item is added.  The item is hidden
                // at this point.  If a show callback is not given the item will
                // have $(this).show() called on it.
                show: function() {
                    if (repeaterSelectTypeKey) {
                        // Custom fields repeater
                        // Show custom field type select form
                        let selectTypeSelector = $(this).find("select[name$='" + "[" + repeaterSelectTypeKey + "]" + "']");
                        mySelf.initTypeSelect2(selectTypeSelector);
                        selectTypeSelector.show();
                        let tagsenumSelector = $(this).find("select[name*='[" + repeaterSelectEnumsKey + "]']");
                        tagsenumSelector.parent().hide();
                    }
                    $(this).slideDown();
                },

                // "hide" is called when a user clicks on a data-repeater-delete
                // element.  The item is still visible.  "hide" is passed a function
                // as its first argument which will properly remove the item.
                // "hide" allows for a confirmation step, to send a delete request
                // to the server, etc.  If a hide callback is not given the item
                // will be deleted.
                hide: function(deleteElement) {
                    if (confirm(i18n.t("common_deleteEltConfirmText"))) {
                        $(this).slideUp(deleteElement);
                    }
                }
            });
            return myRepeater;
        },

        // Update subcategories list custom fields list with the repeater values
        createAssociatedListWithRepeater(repeaterId, repeaterTextNameKey, repeaterSelectTypeKey, repeaterSelectEnumsKey) {
            let listByName = {};
            let associatedList = [];
            let associatedRepeaterVal = $("#" + repeaterId).repeaterVal();
            let repeaterList = repeaterId + "-list";

            if (associatedRepeaterVal && associatedRepeaterVal[repeaterList]) {
                let name, typec;
                for (let j = 0; j < associatedRepeaterVal[repeaterList].length; j++) {
                    name = associatedRepeaterVal[repeaterList][j][repeaterTextNameKey];
                    if (name) {
                        let item = {
                            name: name,
                            isDefault: false
                        };
                        if (repeaterSelectTypeKey) {
                            typec = associatedRepeaterVal[repeaterList][j][repeaterSelectTypeKey];
                            if (typec) {
                                item.typec = typec;
                                if (typec === "Enum") {
                                    item.enumc = associatedRepeaterVal[repeaterList][j][repeaterSelectEnumsKey];
                                    if (!item.enumc || item.enumc.length === 0) {
                                        this.sendNotification({
                                            text: i18n.t("asset_emptyCustomFieldValuesList", { name: name }),
                                            type: "error"
                                        });
                                        return null;
                                    }
                                }
                            }
                        }
                        associatedList.push(item);

                        // Check duplicate name
                        if (listByName[name]) {
                            // name already defined
                            let key_duplicated = "common_duplicatedEltName";
                            switch (repeaterId) {
                                case "kt_repeater_subcategory":
                                    key_duplicated = "asset_duplicatedSubcategoryName";
                                    break;
                                case "kt_repeater_customfield":
                                    key_duplicated = "asset_duplicatedCustomFieldName";
                                    break;
                            }
                            this.sendNotification({
                                text: i18n.t(key_duplicated, { name: name }),
                                type: "error"
                            });
                            return null;
                        }
                        listByName[name] = true;

                    }
                }
            }
            return associatedList;
        },

        initTypeSelect2(selector, value, index) {
            let mySelf = this;
            commonVueHelper.destroySelect2(selector);

            selector
                .select2({
                    placeholder: i18n.t("common_selectType"),
                    data: mySelf.customFieldTypesData,
                    width: "100%",
                    minimumResultsForSearch: -1
                })
                .val(value)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    mySelf.customFields[index]["typec"] = $(this).val();
                    let name = $(this).prop("name");

                    const nameURL = name.replace("select-type", "text-URL");

                    name = name.replace("select-type", "select-enums");

                    let urlSelector = $("#kt_repeater_customfield").find("input[name*='" + nameURL + "'][data-url*='" + index + "']");
                    let enumsSelector = $("#kt_repeater_customfield").find("select[name*='" + name + "'][data-enum*='" + index + "']");

                    if ($(this).val() === "Enum") {
                        urlSelector.parent().hide();
                        mySelf.initEnumsSelect2(enumsSelector, enumsSelector.val(), index);
                        enumsSelector.parent().show();
                    } else if ($(this).val() === "URL") {
                        enumsSelector.parent().hide();
                        urlSelector.parent().show();
                    } else {
                        enumsSelector.parent().hide();
                        urlSelector.parent().hide();
                    }
                });
        },

        updateTypeSelect2(selector) {
            let mySelf = this;
            const currentValue = selector.val();
            // Remove old options
            selector.empty();
            // Unit translation update
            selector
                .select2({
                    placeholder: i18n.t("common_selectType"),
                    data: mySelf.customFieldTypesData
                })
                .val(currentValue)
                .trigger("change");
        },

        initEnumsSelect2(selector, values, index) {
            let mySelf = this;
            let dataValues = [];
            if (Array.isArray(values)) {
                dataValues = values;
            }
            // Init Select2 data of categories
            commonVueHelper.destroySelect2(selector);
            selector
                .select2({
                    placeholder: i18n.t("common_enterValues"),
                    tags: true,
                    tokenSeparators: [","],
                    data: dataValues,
                    width: "100%",
                    selectOnClose: true,
                    dropdownCssClass: "enumsSelect2Dropdown"
                })
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    mySelf.customFields[index].enumc =  $(this).val();
                });
            if (dataValues.length > 0) {
                selector.val(dataValues).trigger("change.select2");
            }
            // Force display of placeholder
            $(".select2-search__field").css("width", "500%");
            // Workaround to remove results
            selector.on("select2:opening select2:closing", function() {
                setTimeout(() => {
                    $(".select2-results__options")
                        .find(".select2-results__option")
                        .remove();
                }, 1);
            });
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
    border-color: rgb(235, 237, 242) !important;
}

.imagePreview {
    height: 45px;
    width: 45px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 300px;
}
</style>

<style>
.enumsSelect2Dropdown {
    display: none;
}
</style>
